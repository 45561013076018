var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"SeIR9TNHQ3lri-ZE9d9EH"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  tunnelRoute: undefined,
  dsn:
    SENTRY_DSN
    || 'https://44735229e31445988d63d1ad2d34f7cb@o450742.ingest.sentry.io/5435573',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
  environment:
    process.env.NEXT_PUBLIC_APP_DOMAIN
    === ('https://shift.ms' || 'https://shift.ms/')
      ? 'production'
      : 'staging',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
